<template>
  <div>
    <div>
      <h1 class="mr-sm-4 header-tablepage">BUSINESS INFORMATION</h1>
    </div>
    <div class="title-tabs mt-3">Branch Info</div>
    <b-container class="no-gutters bg-white" v-if="isLoading">
      <div class="pt-3">
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="Brand Name"
              placeholder="Brand Name"
              v-model="field.business_info[0].value"
              type="text"
              name="branch_name_en"
              isRequired
            />
          </b-col>
          <div class="break-normal"></div>
          <b-col lg="6" md="6" sm="12" xs="12" v-if="field.business_info[11]">
            <InputText
              textFloat="Tax ID."
              placeholder="Tax ID."
              v-model="field.business_info[11].value"
              type="text"
              name="branch_name_en"
              isRequired
            />
          </b-col>
          <b-col sm="12" xs="12" lg="12">
            <InputTextArea
              textFloat="Address"
              placeholder="Please add adress : house no., room, building"
              type="text"
              name="address"
              v-model="field.business_info[1].value"
            />
          </b-col>
          <template v-for="fieldBi of field.business_info">
            <b-col
              lg="6"
              md="6"
              sm="12"
              xs="12"
              :key="fieldBi.id"
              v-if="checkField(fieldBi.id)"
            >
              <InputText
                :textFloat="fieldBi.name.split('_').join(' ')"
                :placeholder="fieldBi.name"
                type="text"
                class="upper-text"
                :name="fieldBi.name"
                v-model="fieldBi.value"
              />
            </b-col>
          </template>
        </b-row>
      </div>
    </b-container>
    <div v-if="field.social_list.length > 0">
      <div class="title-tabs mt-3">Social Media Info</div>
      <b-container class="no-gutters bg-white" v-if="isLoading">
        <div class="pt-3">
          <b-row>
            <b-col
              v-for="list of field.social_list"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              :key="'social-' + list.id"
            >
              <InputText
                :textFloat="list.name"
                :placeholder="list.name"
                type="text"
                :name="list.name"
                v-model="list.url"
              />
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
    <FooterAction routePath="/setting" @submit="saveBusinessConfig()" />
    <ModalLoading ref="modalLoading" />
  </div>
</template>
<script>
import ModalLoading from "@/components/modal/ModalLoading";
import InputTextArea from "@/components/inputs/InputTextArea";
import InputText from "@/components/inputs/InputText";

const FieldID = [1, 2, 12];
export default {
  components: {
    InputText,
    ModalLoading,
    InputTextArea,
  },

  data() {
    return {
      isLoading: false,
      form: {
        email: "",
        password: "",
        name: "",
        name_en: "",
        short_name: "",
        active: 1,
        allow_time_change: 1,
        type: [],
        plant_id: "",
        plant_ref_number: "",
        branch_type_id: null,
        branch_group_template_id: null,
        telephone: "",
        open_day: "",
        open_time: null,
        close_time: null,
        address: "",
        subdistrict: "",
        district: "",
        province: "",
        zip_code: "",
        add_sales_channel: [],
        add_sales_id: [],
        sales_id: [],
      },
      field: [],
      selected: null,
      styleDatetime: {
        width: "150%",
        border: "none",
      },
      type: ["Service", "Product"],
    };
  },
  mounted() {
    this.getFieldBussiness();
  },
  methods: {
    checkField(id) {
      return !FieldID.includes(id);
    },
    openClock() {
      this.$refs.TimeInput[0].isOpen = true;
    },
    async getFieldBussiness() {
      const response = await this.axios(`/setting/businessinfo`);
      this.isLoading = true;
      this.field = response.data.detail;
    },
    async saveBusinessConfig() {
      this.$refs.modalLoading.show();
      const result = await this.axios.post(`/setting/businessinfo`, this.field);

      if (result.data.result == 1) {
        this.successAlert().then((val) => val && this.$router.push("/setting"));
      } else {
        this.errorAlert(result.data.message);
      }
      this.$refs.modalLoading.hide();
    },
  },
};
</script>
<style>

.footer {
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 256px;
  right: 0;
  padding: 10px;
  background: #fff;
}
.upper-text label {
  text-transform: capitalize;
}
</style>
